import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import { InferGetServerSidePropsType } from 'next'
import Script from 'next/script'
import { PageStoryblok } from 'src/core/cms/storyblok-component-types'
import { getCartQueries } from 'src/deprecated/api/cart-queries'
import { getInfoQueries } from 'src/deprecated/api/info-queries'
import { getUserQueries } from 'src/deprecated/api/user-queries'
import { Layout } from 'src/deprecated/components/Layout'
import {
  withAuthStatus,
  withServerLogger,
} from 'src/deprecated/lib/authentication/pageHelpers'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { getFlags } from 'src/deprecated/lib/featureFlag'

const HomePage = ({
  story: initialStory,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const story = useStoryblokState<PageStoryblok>(initialStory, {
    preventClicks: true,
  })

  return (
    <>
      <Script id="organization-metadata" type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "@id": "https://www.qogita.com/#organization",
              "name": "Qogita",
              "description": "Qogita is a B2B wholesale marketplace specializing in branded health and beauty products. Headquartered in the UK, Qogita operates across the entire Europe. The platform allows retailers to purchase authentic products directly from a vast network of brands and trusted wholesalers across Europe.",
              "logo": "https://static.prod.qogita.com/logo/qogita.png",
              "url": "https://www.qogita.com/",
              "contactPoint": [
                {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "email": "support@qogita.com",
                  "areaServed": ["EU", "UK"],
                  "availableLanguage": ["English"]
                },
                {
                  "@type": "ContactPoint",
                  "contactType": "Sales",
                  "email": "sales@qogita.com",
                  "areaServed": ["EU", "UK"],
                  "availableLanguage": ["English"]
                }
              ],
              "address": [
                {
                  "@type": "PostalAddress",
                  "streetAddress": "1 Poultry",
                  "addressLocality": "London",
                  "postalCode": "EC2R 8EJ",
                  "addressCountry": "UK"
                },
                {
                  "@type": "PostalAddress",
                  "streetAddress": "Weesperplein 4B",
                  "addressLocality": "Amsterdam",
                  "postalCode": "1018 XA",
                  "addressCountry": "NL"
                }
              ],
              "sameAs": [
                "https://www.instagram.com/qogita_wholesale/",
                "https://uk.linkedin.com/company/qogita",
                "https://x.com/qogita",
                "https://www.youtube.com/@QogitaWholesale",
                "https://www.facebook.com/qogita/"
              ]
            }
          `}
      </Script>
      <Layout
        pageBlok={story?.content || null}
        padded={false}
        title={
          story?.content.metadata?.title ||
          "Europe's wholesale marketplace for health & beauty retailers | Qogita"
        }
        description={
          story?.content.metadata?.description ||
          'Access exclusive brands, low prices and hot deals from a combined catalog of 400+ suppliers. 10k+ brands available with guaranteed authenticity approved for EU & UK retail. Make procurement simple with our one-stop-shop solution.'
        }
      >
        {story ? (
          <StoryblokComponent blok={story.content} />
        ) : (
          <div>story is null</div>
        )}
      </Layout>
    </>
  )
}

export const getServerSideProps = withServerLogger(
  withAuthStatus(async ({ query }, { canaryClient, ...auth }) => {
    // We don't want to run server-side fetching in playwright tests as they'll try to hit the real canary API
    if (environment.IS_E2E) return { props: {} }

    const userQueries = getUserQueries(canaryClient)
    const cartQueries = getCartQueries(canaryClient)
    const infoQueries = getInfoQueries(canaryClient)

    const featureFlagState = await getFlags()

    const queryClient = new QueryClient()
    const isInStoryblokIframe = Boolean(query._storyblok)

    await Promise.all([
      queryClient.prefetchQuery(infoQueries.all()),
      auth.isAuthenticated
        ? queryClient.prefetchQuery(userQueries.detail())
        : undefined,
      auth.isAuthenticated
        ? queryClient.prefetchQuery(cartQueries.activeDetail())
        : undefined,
    ])

    const slug = 'home'
    const storyblokApi = getStoryblokApi()
    const { data } = await storyblokApi.get(`cdn/stories/${slug}`, {
      version: isInStoryblokIframe ? 'draft' : 'published',
      resolve_links: 'url',
      resolve_relations: ['testimonialSimplePanel.testimonial', 'page.footer'],
    })

    return {
      props: {
        story: data.story,
        featureFlagState,
        dehydratedState: dehydrate(queryClient),
      },
    }
  }),
)

export default HomePage
